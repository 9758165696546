<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-slot></v-slot>
          <v-card-title class="title">
            Admission Request
            <v-spacer></v-spacer>
            <v-btn outlined small color="warning" @click="downloadAdmissionForm()">Download Form Instead</v-btn>

          </v-card-title>
          <v-scroll-y-transition>
            <v-card outlined>
              <v-card-title class="pb-0 mb-0">
                <v-flex xs2 sm2>
                  <v-calendar-field
                    id-val="date"
                    v-model="filters.date"
                    label="Date"
                  >
                  </v-calendar-field>
                </v-flex>
                <v-flex xs2>
                  <v-select
                    :items="grade"
                    class="pa-0"
                    label="Select grade"
                    outlined
                    dense
                    v-model="filters.grade_id"
                  />
                </v-flex>
                <v-flex xs2>
                  <v-select
                    :items="gender"
                    class="pa-0"
                    label="Select Gender"
                    outlined
                    dense
                    v-model="filters.gender_id"
                  />
                </v-flex>
                <v-flex xs2>
                  <v-select
                    :items="cities"
                    class="pa-0"
                    label="Select City"
                    outlined
                    dense
                    v-model="filters.city_id"
                  />
                </v-flex>
                <v-flex xs2>
                  <v-select
                    :items="status"
                    class="pa-0"
                    label="Select Status"
                    outlined
                    dense
                    v-model="filters.status.value"
                  />
                </v-flex>
                <v-flex xs1>
                  <search-button
                    :permission="true"
                    style=""
                    @action="get()"
                  ></search-button>
                </v-flex>
                <v-flex xs1>
                  <search-button
                    :disabled="(filters.date || filters.grade_id || filters.gender_id || filters.city_id)== ''"
                    :permission="true"
                    :color="(filters.date || filters.grade_id || filters.gender_id || filters.city_id)== '' ? '' :'red'"
                    icon='fas fa-times'
                    style=""
                    @action="reset()"
                  ></search-button>
                </v-flex>
              </v-card-title>
            </v-card>
          </v-scroll-y-transition>
          <v-data-table
            :headers="headers"
            :loading="form.loading"
            sort-by="student_name"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            footer-props.items-per-page-options="rowsPerPageItems"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td class="text-center">{{ index + form.items.meta.from }}</td>
                <td class="text-left">
                  <span>{{ item.student_name }} </span>
                  <div>
                    <span style="font-size: 10px; color: #666"
                      >({{ item.guardian_name }})</span
                    >
                  </div>
                </td>

                <td class="text-left">{{ item.guardian_contact }}</td>
                <td class="text-left">{{ item.grade }}</td>
                <td class="text-left">{{ item.gender | capitalize }}</td>
                <td class="text-center">{{ item.dob }}</td>
                <td class="text-center">{{ item.applied_at }}</td>
                <td class="text-center">{{ item.city }}</td>
                <td class="text-center">
                  <v-chip
                    style="cursor: pointer"
                    small
                    label
                    class="mr-1 mb-1 mt-1"
                    >{{ item.father_name | capitalize }}
                  </v-chip>
                  <v-chip
                    style="cursor: pointer"
                    small
                    label
                    class="mr-1 mb-1 mt-1"
                    >{{ item.mother_name | capitalize }}
                  </v-chip>
                  <v-chip
                    style="cursor: pointer"
                    small
                    label
                    class="mr-1 mb-1 mt-1"
                    >{{ item.grand_father_Name | capitalize }}
                  </v-chip>
                </td>
                <td class="text-right">
                  <view-button
                    label="Admit"
                    permission="section-read"
                    @click.native="admit(item.id)"
                  />
                  <delete-button
                    permission="grade-delete"
                    @agree="deleteStudent(item.id)"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import Mixins from "@/library/Mixins";
import Form from "@/library/Form";
import cityData from "@/assets/json/cities.json";
import { redirectDownloadUrl } from "../../../../library/helpers";

export default {
  mixins: [Mixins],
  data: () => ({
    form: new Form({}, "/api/admission-request"),
    headers: [
      { text: "#", align: "center", value: "id", width: 10, sortable: false },
      {
        text: "Student Name/Guardian ",
        align: "left",
        width: 200,
        value: "student_name",
        sortable: false,
      },
      {
        text: "Guardian Contact",
        align: "left",
        width: 150,
        value: "guardian_contact",
        sortable: false,
      },
      { text: "Grade", align: "left", value: "grade", sortable: false },
      { text: "Gender", align: "left", value: "gender", sortable: false },
      {
        text: "Dob",
        align: "center",
        width: 120,
        value: "dob",
        sortable: false,
      },
      {
        text: "Applied At",
        align: "center",
        width: 120,
        value: "applied_at",
        sortable: false,
      },
      {
        text: "City",
        align: "center",
        width: 120,
        value: "city",
        sortable: false,
      },
      {
        text: "Family(Father/Mother/GrandFather)",
        align: "center",
        width: 300,
        value: "father_name",
        sortable: false,
      },
      { text: "Action", align: "right", width: 200, sortable: false },
    ],
    student_admit_id: "",
    grade: [],
    filters: {
      date: "",
      grade_id: "",
      gender_id: "",
      city_id: "",
      status: {
        value: 0,
        text: "Pending",
      },
    },
    gender: [
      {
        value: "male",
        text: "Male",
      },
      {
        value: "female",
        text: "Female",
      },
    ],
    status: [
      {
        value: 0,
        text: "Pending",
      },
      {
        value: 1,
        text: "Approved",
      },
    ],
    cities: [],
    cityData,
  }),
  watch: {
    pagination: function() {
      this.get();
    },
  },
 
  mounted() {
    this.get();
    this.getGrades();
    this.getCities();
  },
  methods: {
    reset(){
      this.filters = {
        date: "",
        grade_id: "",
        gender_id: "",
        city_id: "",
         status: {
          value: 0,
          text: "Pending",
        },
      }

    },
    // queryString() {
    //   let json = this.pagination;
    //   return (
    //     "?" +
    //     Object.keys(json)
    //       .map(function (key) {
    //         if (![null, undefined].includes(json[key]))
    //           return (
    //             encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
    //           );
    //       })
    //       .join("&") +
    //     "&date=" +
    //     this.filters.date.dateForm() +
    //     "&gradeId=" +
    //     this.filters.grade_id.toString() +
    //     "&gender=" +
    //     this.filters.gender_id.toString() +
    //     "&city_id=" +
    //     this.filters.city_id.toString() +
    //     "&status=" +
    //     this.filters.status.value.toString()
    //   );
    // },
    getCities() {
      this.cities = cityData.map((item) => {
        return {
          value: item.id,
          text: item.name,
          district_id: item.district_id,
        };
      });
    },
    getGrades() {
      this.$rest
        .get("/api/grades?rowsPerPage=20&sortBy=rank&descending=false")
        .then(({ data }) => {
          this.grade = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    admit(id) {
      this.$router.push({ path: "/admit", query: { id: id } });
    },

     get(params) {
      let extraParams = 
        "date=" +
        this.filters.date.dateForm() +
        "&gradeId=" +
        this.filters.grade_id.toString() +
        "&gender=" +
        this.filters.gender_id.toString() +
        "&city_id=" +
        this.filters.city_id.toString() +
        "&status=" +
        this.filters.status.value.toString()
      let query = [null, undefined].includes(params)
        ? this.queryString(extraParams)
        : params;
      return this.form.get(null, query).then(({ data }) => {
        // this.pagination.totalItems = data.meta.total;
      });
    },

    // get(params) {
    //   let query = [null, undefined].includes(params)
    //     ? this.queryString()
    //     : params;
    //     console.log(query)
    //   return this.form.get(null, query).then(({ data }) => {
    //     // this.pagination.totalItems = data.meta.total;
    //   });
    // },
    deleteStudent(id) {
      this.$rest
        .put("/api/delete-admission-request/" + id)
        .then((res) => {
          if (res.status == 200) {
            this.$events.fire("notification", {
              message: "Deleted Successfully",
              status: "success",
            });
            this.get();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    downloadAdmissionForm(){
        redirectDownloadUrl({uri:'/print/academic/admisssion-form'})
    }
  },
};
</script>
